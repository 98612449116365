'use strict';

(function ($, DIC) {
	var a = 'is-active';

	var mainSlider = function mainSlider() {
		if ($('.mod-mainSlide').length) {
			$('.mod-mainSlide').slick({
				autoplay: true,
				autoplaySpeed: 6000,
				slidesToShow: 1,
				slidesToScroll: 1,
				loop: true,
				arrows: true,
				prevArrow: '<a class="slick-prev" href="#"></a>',
				nextArrow: '<a class="slick-next" href="#"></a>',
				dots: false,
				centerMode: true,
				variableWidth: true,
				responsive: [{
					breakpoint: 1152,
					settings: {
						centerMode: false,
						variableWidth: false
					}
				}]
			});
		}
	};
	var pickupSlider = function pickupSlider() {
		if ($('.mod-pickup-slide').length) {
			$('.mod-pickup-slide').slick({
				autoplay: false,
				slidesToShow: 3,
				slidesToScroll: 1,
				loop: true,
				arrows: true,
				prevArrow: '<a class="slick-prev" href="#"></a>',
				nextArrow: '<a class="slick-next" href="#"></a>',
				dots: false,
				centerMode: true,
				variableWidth: true,
				initialSlide: 1,
				responsive: [{
					breakpoint: 1260,
					settings: {
						slidesToShow: 1,
						initialSlide: 0
					}
				}]
			});
		}
	};
	var productsSlider = function productsSlider() {
		if ($('.mod-products-slide').length) {
			$('.mod-products-slide').slick({
				autoplay: false,
				slidesToShow: 3,
				slidesToScroll: 1,
				loop: true,
				arrows: true,
				prevArrow: '<a class="slick-prev" href="#"></a>',
				nextArrow: '<a class="slick-next" href="#"></a>',
				dots: false,
				infinite: true,
				responsive: [{
					breakpoint: 1260,
					settings: {
						slidesToShow: 1
					}
				}]
			});
		}
	};
	var playYoutubeModal = function playYoutubeModal() {
		if ($('.js-modalMovie').length) {
			$('.js-modalMovie').magnificPopup({
				disableOn: false,
				type: 'iframe',
				iframe: {
					patterns: {
						youtube: {
							index: 'youtube.com',
							id: 'v=',
							src: '//www.youtube.com/embed/%id%?rel=0&autoplay=1'
						}
					}
				},
				mainClass: 'mfp-fade',
				removalDelay: 160,
				preloader: false,
				fixedContentPos: false
			});
		}
	};
	var playMp4Modal = function playMp4Modal() {
		if ($('.js-modalMP4').length) {
			$('.js-modalMP4').magnificPopup({
				type: 'inline',
				callbacks: {
					open: function open() {
						$('html').css('margin-right', 0);
						$(this.content).find('video')[0].play();
					},
					close: function close() {
						$(this.content).find('video')[0].load();
					}
				}
			});
		}
	};
	var linkChange = function linkChange() {
		var lang = location.pathname.split('/')[1];
		var mpoketLang = void 0;
		if (lang === 'ja') {
			mpoketLang = 'all';
		} else {
			mpoketLang = 'enall';
		}
		// すべてのニュース
		var getJSONcmsTop = function getJSONcmsTop() {
			return $.getJSON('/' + lang + '/ajax/index.json');
		};
		// irニュース
		var getJSONcmsIr = function getJSONcmsIr() {
			return $.getJSON('/' + lang + '/ajax/ir/index.json');
		};
		// マジカルポケット、すべてのニュース
		var getJSONmpocket = function getJSONmpocket() {
			return $.ajax({
				type: 'GET',
				url: 'https://xml.irpocket.com/4631/JS/release-' + mpoketLang + '-all.js',
				dataType: 'jsonp',
				jsonp: 'callback',
				jsonpCallback: 'ir20handler'
			});
		};
		// irナビ部分リンク設定
		if ($('.js-ir-nav-news-listLink').length) {
			var link = $('.js-ir-nav-news-listLink');
			getJSONcmsIr().done(function (cms) {
				getJSONmpocket().done(function (mpocket) {
					var mpocketListdate = new Date(mpocket.item[0].published);
					var mpocketListyear = mpocketListdate.getFullYear();
					var cmsListdate = new Date(cms.rt_bn_news_list[0].cf_n_date);
					var cmsListyear = cmsListdate.getFullYear();
					var latestYear = void 0;
					if (mpocketListyear > cmsListyear) {
						latestYear = mpocketListyear;
					} else {
						latestYear = cmsListyear;
					}
					for (var j = 0; j < link.length; j++) {
						link.eq(j).attr('href', '/' + lang + '/news/' + latestYear + '/ir/');
					}
				});
			});
		}
		// 全般'/lang/news/'の年次設定
		// if($('.js-news-all-listLink').length) {
		// 	let link = $('.js-news-all-listLink');
		// 	getJSONcmsTop().done((cms) => {
		// 		getJSONmpocket().done((mpocket) => {
		// 			let mpocketListdate = new Date(mpocket.item[0].published);
		// 			let mpocketListyear = mpocketListdate.getFullYear();
		// 			let cmsListdate = new Date(cms.rt_bn_news_list[0].cf_n_date);
		// 			let cmsListyear = cmsListdate.getFullYear();
		// 			let latestYear;
		// 			if(mpocketListyear > cmsListyear) {
		// 				latestYear = mpocketListyear;
		// 			} else {
		// 				latestYear = cmsListyear;
		// 			}
		// 			for(let j = 0; j < link.length; j++) {
		// 				link.eq(j).attr('href', '/'+lang+'/news/'+latestYear+'/');
		// 			}
		// 		});
		// 	});
		// }
	};

	mainSlider();
	pickupSlider();
	productsSlider();
	playYoutubeModal();
	playMp4Modal();
	linkChange();
})(window.jQuery, window.DIC);