'use strict';

window.console || (window.console = {
	log: function log() {}
});
var DIC = window.DIC || null;
var cssua = window.cssua || null;

(function ($) {
	var htmlHasClass = function htmlHasClass(_class) {
		return $('html').hasClass(_class);
	};
	var siteName = 'dic-global.com';
	var a = 'is-active';
	var v = 'is-visible';
	var f = 'is-fixed';
	var slideSpeed = '400';

	DIC = {
		va: {
			pathname: location.pathname,
			device: {
				desktop: 1024,
				sp: 768
			},
			window: {
				width: $(window).width(),
				height: $(window).height()
			},
			isMobile: htmlHasClass('ua-mobile')
		},
		localDecision: function localDecision() {
			var regExp = new RegExp('(test.' + siteName + '.co.jp|copre.jp|localhost|192.168.0.|192.168.11.|192.168.1.)', 'g');
			return regExp.test(location.hostname);
		},
		localLoading: function localLoading() {
			var _this = this;
			// ローカル環境ならincludeをAjaxで読み込む
			var key = 'inc_';
			var ajaxArray = [];
			var ajaxArrayList = [];
			var i, j;
			var includeClass = document.querySelectorAll('[class*="' + key + '"]');
			var includeClassLen = includeClass.length;

			for (i = 0; i < includeClassLen; i++) {
				var path = includeClass[i].innerHTML.split(' ')[1];
				ajaxArray.push(path);
			}
			var ajaxLen = ajaxArray.length;

			if (ajaxLen > 0) {
				for (i = 0; i < ajaxLen; i++) {
					ajaxArrayList[i] = $.ajax({
						type: 'GET',
						url: ajaxArray[i] + '.html',
						dataType: 'html',
						timeout: 5000
					});
				}
				$.when.apply($, ajaxArrayList).done(function () {
					for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
						args[_key] = arguments[_key];
					}

					var regExp = new RegExp(key);

					for (i = 0; i < args.length; i++) {
						var result = args[i];
						var position = $(result[0]).filter(':first').attr('class').split(' ');

						for (j = 0; j < position.length; j++) {
							if (position[j].match(regExp)) {
								position = position[j];
								break;
							}
						}
						$('.' + position).html(result[0]).children().unwrap();

						console.log('Succeeded to read the include file!:', position);
					}
					_this.loadDelayScript();
				});
			} else {
				_this.loadDelayScript();
			}
		},
		catchAjaxError: function catchAjaxError(_errorThrown) {
			if (window.ga) {
				window.ga('send', 'event', 'ajaxError', location.href, _errorThrown, 0, true);
			}
		},
		getQuery: function getQuery() {
			var vars = [];
			var hash = null;
			var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
			for (var i = 0; i < hashes.length; i++) {
				hash = hashes[i].split('=');
				vars.push(hash[0]);
				vars[hash[0]] = hash[1];
			}
			return vars;
		},
		nextToggle: function nextToggle() {
			var $switch = $('.js-toggle');

			$switch.on('click', function () {
				$(this).toggleClass(a);
				$(this).next('.js-toggleBlock').stop().slideToggle(slideSpeed).toggleClass(a);
			});

			if (DIC.va.window.width < 768) {
				var $switchsp = $('.js-toggle-sp');

				$switchsp.on('click', function () {
					$(this).toggleClass(a);
					$(this).next('.js-toggle-spBlock').stop().slideToggle(slideSpeed).toggleClass(a);
				});
			}
		},
		easeScroll: function easeScroll() {
			$('a[rel="scroll"]').on('click', function (event) {
				var speed = 1200;
				if (/#/.test(event.currentTarget.href)) {
					scrollToObject(event.currentTarget.href, speed);
				}
				return false;
			});
			function scrollToObject(_self, _speed) {
				var hash = '#' + _self.split('#')[1];
				var pos = hash === '#pagetop' ? 0 : $(hash).offset().top;

				if (hash !== '#pagetop') {
					if (DIC.va.window.width < DIC.va.device.sp) {
						pos -= 60;
					} else {
						pos -= 100;
					}
				}

				$('html,body').stop().animate({ scrollTop: pos }, _speed, 'easeOutExpo');
			}
		},
		easeScrollHash: function easeScrollHash() {
			var hash = location.hash;
			if (hash) {
				var _hash = '#' + hash.split('#')[1];
				if ($(_hash).length) {
					$('body,html').css('opacity', 0);
					$('body,html').stop().scrollTop(0);
					$(window).on('load', function () {
						$('html, body').css('display', 'block');
						_hash = '#' + hash.split('#')[1];
						var pos = $(_hash).offset().top;

						if (DIC.va.window.width <= DIC.va.device.desktop) {
							pos -= 60;
						} else {
							pos -= 100;
						}
						$('body, html').css('opacity', 1);
						$('html, body').stop().animate({ scrollTop: pos }, 1200, 'easeOutExpo');
					});
				}
			}
		},
		pageTop: function pageTop() {
			var $pagetop = $('.footer-pagetop');

			$(window).scroll(function (e) {
				var $this = $(e.currentTarget);
				var $footer = $('.footer-bottom');
				var footerPos = $footer.offset().top + $footer.height();
				var thisScroll = $this.scrollTop();
				var pagetopPos = $this.height() + $footer.height() + thisScroll - 120;
				if (DIC.va.window.width < DIC.va.device.sp) {
					pagetopPos = $this.height() + $footer.height() + thisScroll - 150;
				}

				if (thisScroll > '150') {
					$pagetop.addClass(v);
				} else {
					$pagetop.removeClass(v);
				}
				if (footerPos <= pagetopPos) {
					$pagetop.addClass(f);
				} else {
					$pagetop.removeClass(f);
				}
			});
		},
		headerMenuSP: function headerMenuSP() {
			var $menu = $('.header-nav-btn');
			var $menuWrap = $('.header-nav-btnWrap');
			var $menucontent = $('.header-nav');
			var flag = false;
			var scrollTopNow = void 0;

			// ハンバーガーメニュー
			$menu.on('click', function () {
				if (!flag) {
					scrollTopNow = document.body.scrollTop + document.documentElement.scrollTop;
					$menu.addClass(a);
					$menuWrap.addClass(a);
					$menucontent.stop().slideDown(400);
					$('.container').css({
						position: 'fixed',
						width: '100%',
						top: -1 * scrollTopNow,
						left: 0
					});
					$('body').addClass(f);
					flag = true;
				} else {
					$menu.removeClass(a);
					$menuWrap.removeClass(a);
					$menucontent.stop().slideUp(400);
					$('.container').css({
						position: 'static'
					});
					$('body').removeClass(f);

					$(window).scrollTop(scrollTopNow);
					flag = false;
				}
				// サイト検索閉じる
				$('.header-siteSearch-btn').removeClass(a);
				$('.header-siteSearch').removeClass(v);
				setTimeout(function () {
					$('.header-siteSearch').removeClass(a);
				}, 100);
				$('.header-siteSearch').css('height', '0');
				// 言語切り替え閉じる
				$('.header-langBox-btn').removeClass(a);
				$('.header-langBox').removeClass(v);
				setTimeout(function () {
					$('.header-langBox').removeClass(a);
				}, 100);
				$('.header-langBox').css('height', '0');
			});

			// サイト検索
			$('.header-siteSearch-btn').on('click', function () {
				if (!$('.header-siteSearch-btn').hasClass(a)) {
					$('.header-siteSearch-btn').addClass(a);
					$('.header-siteSearch').addClass(a);
					setTimeout(function () {
						$('.header-siteSearch').addClass(v);
					}, 100);
					$('.header-siteSearch').css('height', $('.header-siteSearch-inner').outerHeight(true));
				} else {
					$('.header-siteSearch-btn').removeClass(a);
					$('.header-siteSearch').removeClass(v);
					setTimeout(function () {
						$('.header-siteSearch').removeClass(a);
					}, 100);
					$('.header-siteSearch').css('height', '0');
				}
				// 言語切り替え閉じる
				$('.header-langBox-btn').removeClass(a);
				$('.header-langBox').removeClass(v);
				setTimeout(function () {
					$('.header-langBox').removeClass(a);
				}, 100);
				$('.header-langBox').css('height', '0');
				// モバイル時ハンバーガーメニュー閉じる
				if (DIC.va.window.width <= DIC.va.device.desktop) {
					$menu.removeClass(a);
					$menuWrap.removeClass(a);
					$menucontent.stop().slideUp(400);
					$('.container').css({
						position: 'static'
					});
					$('body').removeClass(f);

					$(window).scrollTop(scrollTopNow);
					flag = false;
				}
			});
			$('.header-siteSearch-close').on('click', function () {
				$('.header-siteSearch-btn').removeClass(a);
				$('.header-siteSearch').removeClass(v);
				setTimeout(function () {
					$('.header-siteSearch').removeClass(a);
				}, 100);
				$('.header-siteSearch').css('height', '0');
			});

			// 言語切り替え
			$('.header-langBox-btn').on('click', function () {
				if (!$('.header-langBox-btn').hasClass(a)) {
					$('.header-langBox').css('left', $('.header-langBox-btn').offset().left);
					$('.header-langBox-btn').addClass(a);
					$('.header-langBox').addClass(a);
					setTimeout(function () {
						$('.header-langBox').addClass(v);
					}, 100);
					$('.header-langBox').css('height', $('.header-langBox-inner').outerHeight(true));
				} else {
					$('.header-langBox-btn').removeClass(a);
					$('.header-langBox').removeClass(v);
					setTimeout(function () {
						$('.header-langBox').removeClass(a);
					}, 100);
					$('.header-langBox').css('height', '0');
				}
				// サイト検索閉じる
				$('.header-siteSearch-btn').removeClass(a);
				$('.header-siteSearch').removeClass(v);
				setTimeout(function () {
					$('.header-siteSearch').removeClass(a);
				}, 100);
				$('.header-siteSearch').css('height', '0');
				// モバイル時ハンバーガーメニュー閉じる
				if (DIC.va.window.width <= DIC.va.device.desktop) {
					$menu.removeClass(a);
					$menuWrap.removeClass(a);
					$menucontent.stop().slideUp(400);
					$('.container').css({
						position: 'static'
					});
					$('body').removeClass(f);

					$(window).scrollTop(scrollTopNow);
					flag = false;
				}
			});
			$('.header-langBox-close').on('click', function () {
				$('.header-langBox-btn').removeClass(a);
				$('.header-langBox').removeClass(v);
				setTimeout(function () {
					$('.header-langBox').removeClass(a);
				}, 100);
				$('.header-langBox').css('height', '0');
			});
			$('.header-langBox').css('left', $('.header-langBox-btn').offset().left);
			$(window).resize(function () {
				$('.header-langBox').css('left', $('.header-langBox-btn').offset().left);
			});
		},
		headerMegaMene: function headerMegaMene() {
			var $nav = $('.header-nav');
			var $btn = $('.header-nav-item-btn');
			var $item = $('.header-nav-item-child');
			var $btn2 = $('.header-nav-item-child_products-title-btn');
			var $item2 = $('.header-nav-item-child_products-list');

			var urlPath = location.pathname.split('/')[2];
			var i = void 0;
			var thisDataNum = void 0;

			$('#current-' + urlPath).addClass('current');

			if (!(DIC.va.window.width < 1024)) {
				if ($('.ua-edge').length || $('.ua-ie').length) {
					$('.header-logo a img').attr('src', '/common/images/color_and_comfort.png');
				}
				$btn.on('mouseenter', function () {
					$btn.removeClass(a);
					$btn2.removeClass(a);
					$item.stop().slideUp(400).removeClass(a);
					$item2.stop().slideUp(400).removeClass(a);

					$(this).addClass(a);
					$(this).next($item).stop().slideDown(400).addClass(a);
					// サイト検索閉じる
					$('.header-siteSearch-btn').removeClass(a);
					$('.header-siteSearch').removeClass(v);
					setTimeout(function () {
						$('.header-siteSearch').removeClass(a);
					}, 100);
					$('.header-siteSearch').css('height', '0');
					// 言語切り替え閉じる
					$('.header-langBox-btn').removeClass(a);
					$('.header-langBox').removeClass(v);
					setTimeout(function () {
						$('.header-langBox').removeClass(a);
					}, 100);
					$('.header-langBox').css('height', '0');
				});
				$btn2.on('mouseenter', function () {
					$('.header-nav-item-child_products').removeClass('header-nav-item-child_products01');
					$('.header-nav-item-child_products').removeClass('header-nav-item-child_products02');
					$('.header-nav-item-child_products').removeClass('header-nav-item-child_products03');
					$('.header-nav-item-child_products').removeClass('header-nav-item-child_products04');
					$btn2.removeClass(a);
					$item2.stop().slideUp(400).removeClass(a);

					if (!$(this).hasClass('header-nav-item-child_products-title-btn01') && !$(this).hasClass('header-nav-item-child_products-title-btn02') && !$(this).hasClass('header-nav-item-child_products-title-btn04')) {
						$('.header-nav-item-child_products').addClass('header-nav-item-child_products03');
					} else if (!$(this).hasClass('header-nav-item-child_products-title-btn01') && !$(this).hasClass('header-nav-item-child_products-title-btn03') && !$(this).hasClass('header-nav-item-child_products-title-btn04')) {
						$('.header-nav-item-child_products').addClass('header-nav-item-child_products02');
					} else if (!$(this).hasClass('header-nav-item-child_products-title-btn02') && !$(this).hasClass('header-nav-item-child_products-title-btn03') && !$(this).hasClass('header-nav-item-child_products-title-btn04')) {
						$('.header-nav-item-child_products').addClass('header-nav-item-child_products01');
					} else {
						$('.header-nav-item-child_products').addClass('header-nav-item-child_products04');
					}

					thisDataNum = $(this).data('num');
					$(this).addClass(a);
					setTimeout(function () {
						$('.header-nav-item-child_products-list' + thisDataNum + '').stop().slideDown(400).addClass(a);
					}, 400);
				});
				$nav.on('mouseleave', function () {
					$btn.removeClass(a);
					$btn2.removeClass(a);
					$item.stop().slideUp(400).removeClass(a);
					$item2.stop().slideUp(400).removeClass(a);
				});
				$item.on('mouseleave', function () {
					$btn.removeClass(a);
					$btn2.removeClass(a);
					$item.stop().slideUp(400).removeClass(a);
					$item2.stop().slideUp(400).removeClass(a);
				});
				$item2.on('mouseleave', function () {
					$btn2.removeClass(a);
					$item2.stop().slideUp(400).removeClass(a);
				});
			} else {
				$btn.on('click', function () {
					$(this).toggleClass(a);
					$(this).next($item).stop().slideToggle(400);
				});
				$btn2.on('click', function () {
					thisDataNum = $(this).data('num');
					$(this).toggleClass(a);
					$('.header-nav-item-child_products-list' + thisDataNum + '').stop().slideToggle(400);
				});
			}
		},
		headerScroll: function headerScroll() {
			var $header = $('.header');
			var m = 'is-minimal';
			var timer, flag;

			$(window).scroll(function (e) {
				var $this = $(e.currentTarget);
				var thisScroll = $this.scrollTop();

				if (timer) clearTimeout(timer);
				if (thisScroll === 0 && flag) {
					$header.removeClass(m);
					flag = false;
				} else if (thisScroll > 0 && !flag) {
					$header.addClass(m);
					flag = true;
				}
			});
		},
		headerLang: function headerLang() {
			var directory = location.pathname.split('/');
			var url = location.pathname;
			var jaHref = void 0,
			    enHref = void 0,
			    cnHref = void 0;

			if (!(directory[2] === '' || directory[2].match('.html'))) {
				if (!(directory[2] === 'products')) {
					// products以外のディレクトリは、各二階層目のページをリンクに設定
					jaHref = '/ja/' + directory[2] + '/';
					enHref = '/en/' + directory[2] + '/';
					cnHref = '/cn/' + directory[2] + '/';
				} else {
					if (directory[3] === '' || directory[3].match('.html') || directory[3] === 'business' || directory[3] === 'application' || directory[3] === 'finder_coating') {
						// urlpathの言語部分のみを各言語に差し替えたものをリンクに設定
						jaHref = url.replace('/en/', '/ja/').replace('/cn/', '/ja/');
						enHref = url.replace('/cn/', '/en/').replace('/ja/', '/en/');
						cnHref = url.replace('/ja/', '/cn/').replace('/en/', '/cn/');
					} else if (directory[3] === 'search') {
						jaHref = '/ja/products/search/';
						enHref = '/en/products/search/';
						cnHref = '/cn/products/search/';
					}
				}
				for (var i = 0; i < $('.js-header-lang-ja').length; i++) {
					$('.js-header-lang-ja a').eq(i).attr('href', jaHref);
					$('.js-header-lang-en a').eq(i).attr('href', enHref);
					$('.js-header-lang-cn a').eq(i).attr('href', cnHref);
				}
			}
		},
		headerNewsYearList: function headerNewsYearList() {
			if ($('.js-header-newsYearList').length) {
				var latestYear = $('.js-header-newsYearList').find('.rt_latest_year').text();
				var earliestYear = 2015;
				var lang = location.pathname.split('/')[1];
				var text = '';
				var block = void 0;
				if (lang === 'ja') {
					text = '年';
				}
				if (lang === 'cn') {
					earliestYear = 2018;
				}
				for (var i = latestYear; i >= earliestYear; i--) {
					block = '<li class="pure-u-1-3 pure-u-md-1-2"><a href="/' + lang + '/news/' + i + '/" class="header-nav-item-child-link">' + i + text + '</a></li>';
					$('.js-header-newsYearList-ul').append(block);
				}
			}
		},
		share: function share() {
			var shareUrl = document.URL;
			// const encodeurl = encodeURIComponent(url);
			var sharePagetitle = document.title;

			if (!(location.pathname.split('/')[1] === 'cn')) {
				// fb
				if ($('.js-share-btn-facebook').length) {
					$('.js-share-btn-facebook .fb-share-button').attr('data-href', shareUrl);
				}
				if ($('.js-news-share-btn-facebook').length) {
					$('.js-news-share-btn-facebook .fb-share-button').attr('data-href', shareUrl);
				}
				var fbEl = document.createElement('script');
				if (!(location.pathname.split('/')[1] === 'ja')) {
					fbEl.src = 'https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v3.2';
				} else {
					fbEl.src = 'https://connect.facebook.net/ja_JP/sdk.js#xfbml=1&version=v3.2';
				}
				document.body.appendChild(fbEl);
			}

			// line
			if (location.pathname.split('/')[1] === 'ja') {
				if ($('.js-share-btn-line').length) {
					$('.js-share-btn-line .line-it-button').attr('data-url', shareUrl);
				}
				if ($('.js-news-share-btn-line').length) {
					$('.js-news-share-btn-line .line-it-button').attr('data-url', shareUrl);
				}
				var lineEl = document.createElement('script');
				lineEl.src = 'https://d.line-scdn.net/r/web/social-plugin/js/thirdparty/loader.min.js';
				document.body.appendChild(lineEl);
			}
			// mail
			if ($('.js-share-btn-mail').length) {
				$('.js-share-btn-mail a').attr('href', 'mailto:?subject=' + sharePagetitle + '&body=' + shareUrl);
			}
			if ($('.js-news-share-btn-mail').length) {
				$('.js-news-share-btn-mail a').attr('href', 'mailto:?subject=' + sharePagetitle + '&body=' + shareUrl);
			}
			// weibo
			if ($('.js-share-btn-weibo').length) {
				$('.js-share-btn-weibo a').attr('href', 'http://service.weibo.com/share/share.php?url=' + shareUrl + '&appkey=480593611&title=' + sharePagetitle + '&pic=https://www.dic-global.com/common/images/ogp.png');
			}
			if ($('.js-news-share-btn-weibo').length) {
				$('.js-news-share-btn-weibo a').attr('href', 'http://service.weibo.com/share/share.php?url=' + shareUrl + '&appkey=480593611&title=' + sharePagetitle + '&pic=https://www.dic-global.com/common/images/ogp.png');
			}
			// cmsで書き出されたハッシュタグ取得、描画
			if ($('.rt_cf_n_hashtag').length) {
				var hash = $('.rt_cf_n_hashtag').attr('data-cf_n_hashtag').replace('#', '').replace(/#/g, ',');
				// twitter
				$('.js-share-btn-twitter .twitter-share-button').attr('href', 'https://twitter.com/share?ref_src=twsrc%5Etfw&hashtags=' + hash);
				$('.js-news-share-btn-twitter .twitter-share-button').attr('href', 'https://twitter.com/share?ref_src=twsrc%5Etfw&hashtags=' + hash);
			}
			// QR code
			if ($('.js-share-btn-QR').length) {
				$('.js-share-btn-QR .mod-share-QR').magnificPopup({
					disableOn: false,
					type: 'inline',
					mainClass: 'mfp-with-zoom',
					zoom: {
						enabled: true,
						duration: 300,
						easing: 'ease-in-out'
					},
					removalDelay: 160,
					preloader: false,
					fixedContentPos: false
				});
				$('.js-share-btn-QR .mod-share-QR-content-in').qrcode(location.href);
			}
		},
		sideNavCurrent: function sideNavCurrent() {
			var openToggle = function openToggle(_$ele) {
				_$ele.addClass('is-current');
				_$ele.parents('.mod-column-nav-list-li').addClass(a);
				_$ele.parents('.mod-column-nav-list-li').find('.mod-column-nav-list-head, .mod-column-nav-list-body').addClass(a);

				if (_$ele.hasClass('mod-column-nav-list-head-a')) {
					_$ele.parents('.mod-column-nav-list-li').addClass('is-current');
				}
			};
			if ($('.mod-column-nav').length) {
				var openFlg = false;
				$('.mod-column-nav').find('a').each(function (idx, ele) {
					if ($(ele).attr('href') === DIC.va.pathname) {
						openToggle($(ele));
						openFlg = true;
					}
				});
				if (!openFlg) {
					$('.mod-column-nav').find('a').each(function (idx, ele) {
						if (DIC.va.pathname.indexOf($(ele).attr('href')) !== -1) {
							openToggle($(ele));
						}
					});
				}
			}
		},
		imgModal: function imgModal() {
			if ($('.js-modalImg').length) {
				$('.js-modalImg').magnificPopup({
					disableOn: false,
					type: 'image',
					mainClass: 'mfp-with-zoom',
					zoom: {
						enabled: true,
						duration: 300,
						easing: 'ease-in-out'
					},
					removalDelay: 160,
					preloader: false,
					fixedContentPos: false
				});
			}
		},
		fileSizeEx: function fileSizeEx() {
			if ($('.rt_file_size').length) {
				$('.rt_file_size').each(function () {
					$(this).text('［' + bytesToSize($(this).text()) + '］');
				});
			}
			function bytesToSize(bytes) {
				var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
				if (bytes === 0) {
					return 'n/a';
				}
				var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
				if (i === 0) {
					return bytes + ' ' + sizes[i];
				}
				return (bytes / Math.pow(1024, i)).toFixed(1) + ' ' + sizes[i];
			}
		},
		modal: function modal() {
			$('.mod-modal').on('click', function (e) {
				var HTML = '<div class="mod-modal-overlay">';
				HTML += '<div class="mod-modal-closeArea"></div>' + '<span class="mod-modal-closeBtn"><span></span></span>' + ($(e.currentTarget).find('img').prop('outerHTML') + '</div>');
				$('body').append(HTML);
				var $overlay = $('.mod-modal-overlay');
				$overlay.addClass(a);
				setTimeout(function () {
					$overlay.addClass(v);
					$('.mod-modal-closeArea, .mod-modal-closeBtn').on('click', function () {
						$overlay.removeClass(v);
						setTimeout(function () {
							$overlay.removeClass(a);
							$overlay.remove();
						}, 400);
					});
				});
			});
		},
		hreflang: function hreflang() {
			var thisLang = location.pathname.split('/')[1];
			function hreflangSet($hreflang, $href) {
				var link_lang = document.createElement('link');
				var langUrl = 'https://www.dic-global.com/' + $href + '/';
				var watchUrl = 'https://www.dic-global.com' + location.pathname;
				link_lang.rel = 'alternate';
				link_lang.href = watchUrl.replace('https://www.dic-global.com/en/', langUrl).replace('https://www.dic-global.com/ja/', langUrl).replace('https://www.dic-global.com/cn/', langUrl).replace('https://www.dic-global.com/ap/', langUrl).replace('https://www.dic-global.com/ph/', langUrl).replace('https://www.dic-global.com/bd/', langUrl).replace('https://www.dic-global.com/my/', langUrl).replace('https://www.dic-global.com/vn/', langUrl);
				link_lang.hreflang = $hreflang;
				$('head').append(link_lang);
			}

			// ①en|ap|ph|bd|my|vnすべてのページが存在するパターン
			function hreflangAdd($lang) {
				if (thisLang === $lang) {
					if (location.pathname === '/' + $lang + '/csr/' || location.pathname === '/' + $lang + '/csr/index.html' || location.pathname === '/' + $lang + '/ir/' || location.pathname === '/' + $lang + '/ir/index.html' || location.pathname === '/' + $lang + '/event/' || location.pathname === '/' + $lang + '/event/index.html' || location.pathname === '/' + $lang + '/contact/' || location.pathname === '/' + $lang + '/contact/index.html' || location.pathname === '/' + $lang + '/privacy/' || location.pathname === '/' + $lang + '/privacy/index.html' || location.pathname === '/' + $lang + '/terms/' || location.pathname === '/' + $lang + '/terms/index.html' || location.pathname.split('/')[2] === 'r_and_d' || location.pathname.split('/')[2] === 'rss') {
						hreflangSet('en-PH', 'ph');
						hreflangSet('en-BD', 'bd');
						hreflangSet('en-MY', 'my');
						hreflangSet('en-VN', 'vn');
						hreflangSet('en-SG', 'ap');
						hreflangSet('en-ID', 'ap');
						hreflangSet('en-TH', 'ap');
						hreflangSet('en-MM', 'ap');
						hreflangSet('en-KH', 'ap');
						hreflangSet('en-BN', 'ap');
						hreflangSet('en-LA', 'ap');
						hreflangSet('en-IN', 'ap');
						hreflangSet('en-PK', 'ap');
						hreflangSet('en-LK', 'ap');
						hreflangSet('en-NP', 'ap');
						hreflangSet('en-BT', 'ap');
						hreflangSet('en-AU', 'ap');
						hreflangSet('en-NZ', 'ap');
						hreflangSet('x-default', 'en');
					}
				}
			}
			hreflangAdd('en');
			hreflangAdd('ap');
			hreflangAdd('ph');
			hreflangAdd('bd');
			hreflangAdd('my');
			hreflangAdd('vn');

			// ②ap|en|ph|bd|my|vnが存在するパターン（products・about配下）
			function hreflangAddNotAp($lang) {
				if (thisLang === $lang) {
					if (location.pathname.split('/')[2] === 'about') {
						if (!(location.pathname.split('/')[3] === 'philippines.html') && !(location.pathname.split('/')[3] === 'bangladesh.html') && !(location.pathname.split('/')[3] === 'malaysia.html') && !(location.pathname.split('/')[3] === 'vietnam.html') && !(location.pathname.split('/')[3] === 'dic_in_ap.html')) {
							hreflangSet('en-PH', 'ph');
							hreflangSet('en-BD', 'bd');
							hreflangSet('en-MY', 'my');
							hreflangSet('en-VN', 'vn');
							hreflangSet('en-SG', 'ap');
							hreflangSet('en-ID', 'ap');
							hreflangSet('en-TH', 'ap');
							hreflangSet('en-MM', 'ap');
							hreflangSet('en-KH', 'ap');
							hreflangSet('en-BN', 'ap');
							hreflangSet('en-LA', 'ap');
							hreflangSet('en-IN', 'ap');
							hreflangSet('en-PK', 'ap');
							hreflangSet('en-LK', 'ap');
							hreflangSet('en-NP', 'ap');
							hreflangSet('en-BT', 'ap');
							hreflangSet('en-AU', 'ap');
							hreflangSet('en-NZ', 'ap');
							hreflangSet('x-default', 'en');
						}
					} else if (location.pathname === '/' + $lang + '/products/' || location.pathname === '/' + $lang + '/products/index.html') {
						hreflangSet('en-PH', 'ph');
						hreflangSet('en-BD', 'bd');
						hreflangSet('en-MY', 'my');
						hreflangSet('en-VN', 'vn');
						hreflangSet('en-SG', 'ap');
						hreflangSet('en-ID', 'ap');
						hreflangSet('en-TH', 'ap');
						hreflangSet('en-MM', 'ap');
						hreflangSet('en-KH', 'ap');
						hreflangSet('en-BN', 'ap');
						hreflangSet('en-LA', 'ap');
						hreflangSet('en-IN', 'ap');
						hreflangSet('en-PK', 'ap');
						hreflangSet('en-LK', 'ap');
						hreflangSet('en-NP', 'ap');
						hreflangSet('en-BT', 'ap');
						hreflangSet('en-AU', 'ap');
						hreflangSet('en-NZ', 'ap');
						hreflangSet('x-default', 'en');
					} else if (location.pathname.split('/')[2] === 'products') {
						if (location.pathname.split('/')[3] === 'search' || location.pathname.split('/')[3] === 'finder_coating' || location.pathname.split('/')[3] === 'business' || location.pathname.split('/')[3] === 'application' || location.pathname.split('/')[3] === 'coating' || location.pathname.split('/')[4] === 'contents' || location.pathname.split('/')[5] === 'contents' || location.pathname.split('/')[4] === 'lineup' || location.pathname.split('/')[5] === 'lineup') {
							hreflangSet('en-PH', 'ph');
							hreflangSet('en-BD', 'bd');
							hreflangSet('en-MY', 'my');
							hreflangSet('en-VN', 'vn');
							hreflangSet('en-SG', 'ap');
							hreflangSet('en-ID', 'ap');
							hreflangSet('en-TH', 'ap');
							hreflangSet('en-MM', 'ap');
							hreflangSet('en-KH', 'ap');
							hreflangSet('en-BN', 'ap');
							hreflangSet('en-LA', 'ap');
							hreflangSet('en-IN', 'ap');
							hreflangSet('en-PK', 'ap');
							hreflangSet('en-LK', 'ap');
							hreflangSet('en-NP', 'ap');
							hreflangSet('en-BT', 'ap');
							hreflangSet('en-AU', 'ap');
							hreflangSet('en-NZ', 'ap');
							hreflangSet('x-default', 'en');
						}
					}
				}
			}
			hreflangAddNotAp('en');
			hreflangAddNotAp('ap');
			hreflangAddNotAp('ph');
			hreflangAddNotAp('bd');
			hreflangAddNotAp('my');
			hreflangAddNotAp('vn');

			// 各国のアバウト（会社情報）ページ
			// if(location.pathname === '/ph/about/philippines.html' || location.pathname === '/bd/about/bangladesh.html' || location.pathname === '/my/about/malaysia.html' || location.pathname === '/vn/about/vietnam.html' || location.pathname === '/ap/dic_in_ap/index.html') {
			// 	let link_langph = document.createElement('link');
			// 	link_langph.rel = 'alternate';
			// 	link_langph.href = 'https://www.dic-global.com/ph/about/philippines.html';
			// 	link_langph.hreflang = 'en-PH';
			// 	$('head').append(link_langph);
			// 	let link_langbd = document.createElement('link');
			// 	link_langbd.rel = 'alternate';
			// 	link_langbd.href = 'https://www.dic-global.com/bd/about/bangladesh.html';
			// 	link_langbd.hreflang = 'en-BD';
			// 	$('head').append(link_langbd);
			// 	let link_langmy = document.createElement('link');
			// 	link_langmy.rel = 'alternate';
			// 	link_langmy.href = 'https://www.dic-global.com/my/about/malaysia.html';
			// 	link_langmy.hreflang = 'en-MY';
			// 	$('head').append(link_langmy);
			// 	let link_langvn = document.createElement('link');
			// 	link_langvn.rel = 'alternate';
			// 	link_langvn.href = 'https://www.dic-global.com/vn/about/vietnam.html';
			// 	link_langvn.hreflang = 'en-VN';
			// 	$('head').append(link_langvn);
			// 	let link_langap = document.createElement('link');
			// 	link_langap.rel = 'alternate';
			// 	link_langap.href = 'https://www.dic-global.com/en//dic_in_ap/';
			// 	link_langap.hreflang = 'x-default';
			// 	$('head').append(link_langap);
			// }
		},
		loadDelayScript: function loadDelayScript() {
			var _this = this;
			_this.nextToggle();
			_this.easeScroll();
			_this.easeScrollHash();
			_this.pageTop();
			_this.headerMenuSP();
			_this.headerMegaMene();
			_this.headerScroll();
			// _this.headerLang();
			_this.headerNewsYearList();
			_this.share();
			_this.sideNavCurrent();
			_this.imgModal();
			_this.fileSizeEx();
			_this.modal();
			_this.hreflang();
		}
	};

	$(function () {
		return DIC.localDecision() ? DIC.localLoading() : DIC.loadDelayScript();
	});
})(window.jQuery);

// クッキー同意
// $(window).on('load', function(){
// 	if($('.footer-gdpr').length) {
// 		if($.cookie('gdpr-popup') !== 'hidden') {
// 			$('.footer-gdpr').addClass('is-active');
// 		}
// 		$('.footer-gdpr-agree').on('click', function(){
// 			// secureは、HTTPS接続の場合のみにcookieを送信(デフォルトはfalse)
// 			$.cookie('gdpr-popup', 'hidden', { expires:90, path:'/', secure:false });
// 			$('.footer-gdpr').removeClass('is-active');
// 		});
// 	}
// });